<template>
  <div class="colorSchemeMain">
    <div class="backBtn" v-if="currView !== 'colorScheme'">
      <p
        class="d-flex align-items-center cursor-pointer"
        @click="hadnleColorSchemeBackButton"
      >
        <b-icon-arrow-left class="mr-2"></b-icon-arrow-left>
        Back
      </p>
    </div>
    <div v-if="currView == 'mainView'">
      <div>
        <p class="pageSettingsTitle">ColorScheme</p>
      </div>

      <div class="row" v-if="isLoadSchemeLoading">
        
        <div class="col-6 mb-3" v-for="index in 6" :key="index">
          <div 
        class=" bg-preload"
        style="height: 80px; width: 100%; border-radius: 8px">
        <div class="into-preload"></div>
      </div>
        </div>
      
      </div>

      <div class="row" v-else>
        <div
          class="col-6"
          v-for="scheme in allSchemeList"
          :key="scheme.id"  
          @click="editScheme(scheme)"
        >
          <div class="singleScheme ">
            <b-dropdown
                                  
                                  right
                                  class="schemeDropDown"
                                  id=""
                                  style="z-index: 1111">
                                  <template
                                    slot="button-content"
                                    class="templateButton">
                                    <b-icon-three-dots-vertical
                                      class="font-weight-bold"
                                      font-size="1"
                                      style="
                                        color: #6d6b6d;
                                      "></b-icon-three-dots-vertical>
                                  </template>
                                 

                                  <b-dropdown-item
                                    @click.stop="removeSchema(scheme.id)"
                                    class="trash d-flex align-items-center"
                                    ><b-icon-trash
                                      class="text-danger mr-2 "></b-icon-trash
                                    >
                                    <span v-if="!isDeleteSchemeLoading"> Delete</span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
                                    </b-dropdown-item
                                  >
                                </b-dropdown>
            <div class="textContainer">
              <span>Aa</span>
            </div>
            <div class="d-flex justify-content-center mt-2">
              <div v-for="(color, index) in scheme.baseColors" :key="index">
                <div
                  class="baseColorContainer mr-1"
                  :style="getBaseColor(color)"
                ></div>
              </div>
            </div>
          </div>

          <p class="pageSettingHeader text-center mt-2">{{ scheme.name }}</p>
        </div>

        <div class="col-6">
          <div
            class="singleScheme"
            @click="addNewScheme"
            style="color: #0397cc; border: 1px dashed #0397cc;height:96px;"
          >
            <b-icon icon="plus" font-scale="2"></b-icon>
          </div>
          <p class="pageSettingHeader text-center mt-2" style="color: #0397cc">
            Add Scheme
          </p>
        </div>
      </div>
    </div>
    <div v-else class="">
      <div>
        <p class="pageSettingsTitle">
          <!-- <span class="mr-1" @click="currView = 'mainView'">
            <b-icon-arrow-left class="mr-2"></b-icon-arrow-left>
          </span> -->
          {{ isSchemeEdit ? "Editing Scheme" : "Add Scheme" }}
        </p>
      </div>

      <div class="addEditScheme d-flex flex-column">
        <div class="addEditInputContainers" style="">
          <div class="schemeNameInput">
            <input
              type="text"
              v-model="singleSchemeColors.name"
              class="w-100 input-border-style"
              placeholder="Enter Scheme Name"
            />
          </div>

          <div class="colorsInputContainer mt-3">
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Background</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.background"
                />
                <input type="text" class="input-border-style"  v-model="singleSchemeColors.background" />
              </div>
            </div>
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Text</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.textColor"
                />
                <input type="text" class="input-border-style"  v-model="singleSchemeColors.textColor" />
              </div>
            </div>
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Selected Options Background</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.selectedOptionsBg"
                />
                <input
                  type="text" class="input-border-style" 
                  v-model="singleSchemeColors.selectedOptionsBg"
                />
              </div>
            </div>
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Non-Selected Option Background</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.nonSelectedOptionsBg"
                />
                <input
                  type="text" class="input-border-style" 
                  v-model="singleSchemeColors.nonSelectedOptionsBg"
                />
              </div>
            </div>
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Option Text</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.optionText"
                />
                <input type="text" class="input-border-style"  v-model="singleSchemeColors.optionText" />
              </div>
            </div>
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Selected Option Text</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.selectedOptionText"
                />
                <input
                  type="text" class="input-border-style" 
                  v-model="singleSchemeColors.selectedOptionText"
                />
              </div>
            </div>
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Button Color</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.generalButtons"
                />
                <input
                  type="text" class="input-border-style" 
                  v-model="singleSchemeColors.generalButtons"
                />
              </div>
            </div>
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Back Button Color</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.backButton"
                />
                <input type="text" class="input-border-style"  v-model="singleSchemeColors.backButton" />
              </div>
            </div>
            <div class="singleColorInput mb-2">
              <p class="title m-0 mb-1">Next Button Color</p>
              <div class="d-flex align-items-center">
                <input
                  type="color"
                  class="colorInput mr-2"
                  v-model="singleSchemeColors.nextButton"
                />
                <input type="text" class="input-border-style"  v-model="singleSchemeColors.nextButton" />
              </div>
            </div>
          </div>
          <p class="pageSettingHeader" v-if="isSchemeEdit">
            * Selecting 'Use Scheme' will apply the scheme colors to all pages.
          </p>
        </div>

        <div>
          <div class="d-flex justify-content-between align-items-center">
            <button
              v-if="isSchemeEdit"
              class="btn saveButtons"
              style="background: #b3afb6"
              :disabled="!isSchemeEdit"
              @click="useScheme"
            >
              Use Scheme
            </button>
            <button class="btn saveButtons" style="background: #4d1b7e;min-width:50px;" @click="handleColorBtnClick">
              <span v-if="!isAddSchemeLoading && !isEditSchemeLoading"> {{ isSchemeEdit ? "Update" : "Save as new Scheme" }}</span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
             
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoadSchemeLoading:false,
      isAddSchemeLoading:false,
      isEditSchemeLoading:false,
      isUseSchemeLoading: false,
      isDeleteSchemeLoading: false,
      currView: "mainView",
      isSchemeEdit: false,
      allSchemeList:[],
      singleSchemeColors: {
        name: ``,
        background: `#FAFAFA`,
        textColor: `#212121`,
        selectedOptionsBg: `#03A9F4`,
        nonSelectedOptionsBg: `#E0E0E2`,
        optionText: `#424242`,
        generalButtons: `#009688`,
        backButton: `#BDBDBD`,
        nextButton: `#FFC107`,
        selectedOptionText: `#FFFFFF`,
      },
    };
  },
  methods: {
    hadnleColorSchemeBackButton() {
      if (this.currView == "mainView") {
        this.$emit("handleColorBackButtonClick");
      } else {
        this.currView = "mainView";
        this.isSchemeEdit = false;
      }
    },
    addNewScheme() {
      this.currView = "addNewScheme";
      this.isSchemeEdit = false;
      this.singleSchemeColors = {
        name: ``,
        background: `#ffffff`,
        textColor: `#000000`,
        selectedOptionsBg: `#f26631`,
        nonSelectedOptionsBg: `#e0e0e0`,
        optionText: `#000000`,
        generalButtons: `#f26631`,
        backButton: `#e0e0e0`,
        nextButton: `#f26631`,
        selectedOptionText: `#ffffff`,
      };
    },
    getBaseColor(color) {
      if (!color) return `background:#000000;`;

      return `background:${color};`;
    },
    editScheme(scheme) {
      this.singleSchemeColors = scheme;
      this.currView = "editView";
      this.isSchemeEdit = true;
    },

    async loadColorSchemes(){

      try {
        this.isLoadSchemeLoading = true
        const quizId = parseInt(localStorage.getItem("QuizID"))

        const response = await axios.get(`/quiz/color/schema/list?quizId=${quizId}`)

        if(response.status == 200){
          this.allSchemeList = response.data.data
        }
      } catch (error) {
        this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error
      }finally{
        this.isLoadSchemeLoading = false
      }
    },
    async removeSchema(id){

      if(!id) return
      try {
        this.isDeleteSchemeLoading = true
        const quizId = parseInt(localStorage.getItem("QuizID"))

        const response = await axios.delete(`/quiz/color/schema/delete?quizId=${quizId}&id=${id}`)

        if(response.status == 200){
          this.$toasted.show("Scheme deleted successfully!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          this.currView = "mainView";
        this.isSchemeEdit = false;

        this.loadColorSchemes()
        }
      } catch (error) {
        this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error
      }finally{
        this.isDeleteSchemeLoading = false
      }
    },
    handleColorBtnClick(){
      if(this.isSchemeEdit){
        this.saveEditScheme()
      } else {
        this.createNewScheme()
      }
    },
    async createNewScheme(){

      if(this.singleSchemeColors.name == ''){
        this.$toasted.show("Scheme name is required", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          return
      }
      try {
        this.isAddSchemeLoading = true
        const payload = this.singleSchemeColors
        payload['quizId'] = parseInt(localStorage.getItem("QuizID"))
        payload['baseColors'] = [this.singleSchemeColors.background , this.singleSchemeColors.generalButtons]



        const response = await axios.post(`/quiz/color/schema/store`,payload)

        if(response.status == 200){
          this.$toasted.show("Scheme added successfully!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          this.currView = "mainView";
        this.isSchemeEdit = false;

        this.loadColorSchemes()
        }
      } catch (error) {
        this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error
      }finally{
        this.isAddSchemeLoading = false
      }
    },
    async saveEditScheme(){

      if(this.singleSchemeColors.name == ''){
        this.$toasted.show("Scheme name is required", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          return
      }
      try {
        this.isEditSchemeLoading = true
        const payload = this.singleSchemeColors
        // payload['quizId'] = parseInt(localStorage.getItem("QuizID"))
        payload['baseColors'] = [this.singleSchemeColors.background , this.singleSchemeColors.generalButtons]



        const response = await axios.post(`/quiz/color/schema/update/${this.singleSchemeColors.id}`,payload)

        if(response.status == 200){
          this.$toasted.show("Scheme edit successfull!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          this.currView = "mainView";
        this.isSchemeEdit = false;

        this.loadColorSchemes()
        }
      } catch (error) {
        this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error
      }finally{
        this.isEditSchemeLoading = false
      }
    },
    async useScheme() {
      // const allPages = JSON.parse(JSON.stringify(this.getAllPages));
    //  const allPages = [...this.getAllPages];
      const allPages = this.getAllPages;
      const {
        background,
        textColor,
        selectedOptionsBg,
        nonSelectedOptionsBg,
        optionText,
        generalButtons,
        backButton,
        nextButton,
        selectedOptionText,
      } = this.singleSchemeColors;

      try {
        this.isUseSchemeLoading = true;

        allPages.forEach((item) => {
          // Update Background
          this.$set(item.pageDesign.pageStyles, "backgroundColor", background);

          if (item.value == "resultPage") {
            item.pageDesign.NoResultBlocksArray.forEach((element) => {
              switch (element.type) {
                case "text":
                  this.$set(element.style, "color", textColor);
                  break;
                case "button":
                  if (typeof element.content != "object") {
                    this.$set(element.style, "backgroundColor", generalButtons);
                  }
                  break;
              }
            });
          }

          // Iterate over blocksArray only once and handle all cases in a single pass
          item.pageDesign.blocksArray.forEach((element) => {
            switch (element.type) {
              case "text":
                this.$set(element.style, "color", textColor);
                break;
              case "button":
                if (typeof element.content != "object") {
                  this.$set(element.style, "backgroundColor", generalButtons);
                } else {
                  this.$set(
                    element.content.backButton.style,
                    "backgroundColor",
                    backButton
                  );
                  this.$set(
                    element.content.nextButton.style,
                    "backgroundColor",
                    nextButton
                  );
                }
                break;
              case "option":
                this.$set(
                  element.style.selectedTab,
                  "backgroundColor",
                  selectedOptionsBg
                );
                this.$set(
                  element.style.selectedTab,
                  "textColor",
                  selectedOptionText
                );
                this.$set(element.style, "color", optionText);
                this.$set(
                  element.style.defaultTab,
                  "backgroundColor",
                  nonSelectedOptionsBg
                );
                break;

              case "freeText":
              this.$set(element.style, "strokeColor", textColor);
              this.$set(element.style, "color", textColor);
              break;
              case "customInput":
              this.$set(element.style, "strokeColor", textColor);
              this.$set(element.style, "color", textColor);
              break;
              case "checkbox":
              this.$set(element.style, "color", textColor);
              break;
              case "product":
                this.$set(element.style, "buttonBgColor", generalButtons);
                break;
            }
          });
        });
        this.$nextTick(() => {
          this.$toasted.show("Color scheme successfully applied", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        });
      } catch (error) {
        console.log("Error Occurred", error);
        this.$toasted.show("Error Occurred while using Scheme", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isUseSchemeLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["getTotalPages"]),
    getAllPages() {
      return this.getTotalPages;
    },
  },
  mounted(){
    this.loadColorSchemes()
  }
};
</script>

<style scoped>
.colorSchemeMain {
  height: calc(100vh - 126px);
}

.addEditScheme {
  height: 100%;
}
.addEditInputContainers {
  height: 100%;
  max-height: 73vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.singleScheme {
  border-radius: 8px;
  border: 1px solid #d2d8e0;
  display: flex;
  min-height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
  position: relative
}

.schemeDropDown{
  position: absolute;
  top:5px;
  right:5px;
}

.textContainer {
  font-family: Inter;
  font-size: 26px;
  font-weight: 500;
  line-height: 31.47px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #18191c;
}

.baseColorContainer {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.schemeNameInput {
  padding: 0px 2px 15px 2px;
  border-bottom: 1px solid #f1f1f4;
}
.schemeNameInput input,
.singleColorInput input[type="text"] {
  border: 0;
  outline: 0;
  color: #73738d;
}
.schemeNameInput input:focus,
.singleColorInput input[type="text"]:focus {
  border: 0;
  outline: 0;
}

.singleColorInput .title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
}

.colorInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px !important;
  height: 30px !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 2px !important;
  margin-right: 5px;
}
.colorInput::-webkit-color-swatch {
  border: 2px solid #cfcece;
}
.colorInput::-moz-color-swatch {
  border: 2px solid #cfcece;
}



.saveButtons {
  border-radius: 6px;
  color: #fff;
  padding: 10px;
}

.schemeDropDown >>> button {
  background: transparent !important;
  border: none;
  margin: 0;
  padding: 0;
}
.schemeDropDown >>> button::after {
  display: none;
}
.schemeDropDown >>> ul {
  border-radius: 4px;
  margin: 0;
  padding: 0;
  max-width:50px !important;
}
.schemeDropDown >>> li a {
  /* color: #ffffff; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 2px;
 text-align: center;
}
.schemeDropDown >>> li a:hover {
  background: transparent;
}
.schemeDropDown >>> li a:active {
  /* color: #fff; */
  text-decoration: none;
  background-color: transparent;
}
</style>
